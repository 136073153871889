<template>
  <div>
    <div v-for="(item, index) in dataList" :key="item.day">
      <div class="vx-row mb-3 mt-3">
        <div class="vx-col w-1/3 mt-auto mb-auto">
          <vs-checkbox v-model="dataList[index].checked" :vs-value="true">{{
            item.day
          }}</vs-checkbox>
        </div>
        <div class="vx-col w-1/3">
          <flat-pickr
            :config="configdateTimePicker"
            v-model="dataList[index].start_time"
            placeholder="Start Time"
            style="width: 75%"
          />
        </div>
        <div class="vx-col w-1/3">
          <flat-pickr
            :config="configdateTimePicker"
            v-model="dataList[index].end_time"
            placeholder="End Time"
            style="width: 75%"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
export default {
  components: {
    flatPickr,
  },
  props: {
    data: {
      type: Array,
    },
  },
  data() {
    return this.initialState();
  },
  methods: {
    initialState() {
      return {
        configdateTimePicker: {
          enableTime: true,
          noCalendar: true,
          dateFormat: "H:i",
          time_24hr: true,
        },
        dataList: [],
      };
    },
    emitData(data) {
      data.forEach(function (item) {
        if (!item.checked) {
          item.start_time = null;
          item.end_time = null;
        }
      });
      this.$emit("data", data);
    },
    getDataDays() {
      this.$http
        .get("/api/v1/master/work-day", {
          params: {
            order: "id",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            var fromParent = this.data;
            var dataList = [];
            resp.data.records.filter(
              (dataItem) => {
                var push = true;
                fromParent.filter(
                  function (valItem) {
                    if (valItem.day == dataItem.day) {
                      dataList.push({
                        day: valItem.day,
                        checked: valItem.checked,
                        start_time: valItem.start_time,
                        end_time: valItem.end_time,
                      });
                      push = false;
                    }
                  },
                  dataItem,
                  dataList,
                  push
                );
                if (push) {
                  dataList.push({
                    day: dataItem.day,
                    checked: false,
                    start_time: "",
                    end_time: "",
                  });
                }
              },
              fromParent,
              dataList
            );
            this.dataList = dataList;
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.getDataDays();
  },
  watch: {
    dataList: {
      deep: true,
      handler(data) {
        this.emitData(data);
      },
    },
  },
};
</script>