<template>
  <div>
    <div v-for="(item, index) in dataList" :key="item.day">
      <div class="vx-row mb-3 mt-3">
        <div class="vx-col w-1/3 mt-auto mb-auto">
          <vs-checkbox
            v-model="dataList[index].checked"
            :vs-value="true"
            :disabled="configs[item.day].enabled"
            >{{ item.day }}</vs-checkbox
          >
        </div>
        <div class="vx-col w-1/3">
          <flat-pickr
            :config="configs[item.day].config"
            v-model="dataList[index].start_time"
            placeholder="Start Time"
            style="width: 75%"
            :disabled="configs[item.day].enabled"
          />
        </div>
        <div class="vx-col w-1/3">
          <flat-pickr
            :config="configs[item.day].config"
            v-model="dataList[index].end_time"
            placeholder="End Time"
            style="width: 75%"
            :disabled="configs[item.day].enabled"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
export default {
  components: {
    flatPickr,
  },
  props: {
    data: {
      type: Array,
    },
    dataWorkOperation: {
      type: Array,
    },
  },
  data() {
    return this.initialState();
  },
  methods: {
    initialState() {
      return {
        configDateTimePicker: {
          enableTime: true,
          noCalendar: true,
          dateFormat: "H:i",
          time_24hr: true,
        },
        dataList: [],
        configs: [],
      };
    },
    emitData(data) {
      data.forEach(function (item) {
        if (!item.checked) {
          item.start_time = null;
          item.end_time = null;
        }
      });
      this.$emit("data", data);
    },
    getDataDays() {
      this.$http
        .get("/api/v1/master/work-day", {
          params: {
            order: "id",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            var getEnabled = this.getEnabled;
            var fromParent = this.data;
            var dataList = [];
            resp.data.records.filter(
              (dataItem) => {
                var push = true;
                fromParent.filter(
                  function (valItem) {
                    if (valItem.day == dataItem.day) {
                      if (getEnabled(valItem.day)) {
                        dataList.push({
                          day: valItem.day,
                          checked: false,
                          start_time: null,
                          end_time: null,
                        });
                      } else {
                        dataList.push({
                          day: valItem.day,
                          checked: valItem.checked,
                          start_time: valItem.start_time,
                          end_time: valItem.end_time,
                        });
                      }
                      push = false;
                    }
                  },
                  dataItem,
                  dataList,
                  push
                );
                if (push) {
                  dataList.push({
                    day: dataItem.day,
                    checked: false,
                    start_time: "",
                    end_time: "",
                  });
                }
              },
              fromParent,
              dataList
            );
            this.dataList = dataList;
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    setConfig() {
      var configs = [];
      var getConfig = this.getConfig;
      var getEnabled = this.getEnabled;
      this.dataList.filter(
        function (item) {
          configs[item.day] = {
            enabled: getEnabled(item.day),
            config: getConfig(item.day),
          };
        },
        configs,
        getConfig,
        getEnabled
      );
      this.configs = configs;
    },
    getConfig(key) {
      var config = {
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
        time_24hr: true,
      };
      this.dataWorkOperation.filter(function (item) {
        if (key == item.day && item.checked) {
          config = {
            enableTime: true,
            noCalendar: true,
            dateFormat: "H:i",
            time_24hr: true,
            minTime: item.start_time,
            maxTime: item.end_time,
          };
        }
      }, config);
      return config;
    },
    getEnabled(key) {
      var status = true;
      this.dataWorkOperation.filter(function (item) {
        if (key == item.day && item.checked) {
          status = false;
        }
      }, status);
      return status;
    },
  },
  mounted() {
    this.getDataDays();
    this.setConfig();
  },
  watch: {
    dataList: {
      deep: true,
      handler(data) {
        this.setConfig();
        this.emitData(data);
      },
    },
    dataWorkOperation() {
      this.getDataDays();
      this.setConfig();
    },
  },
};
</script>