<template>
  <div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <vs-input class="w-full" label="Name" v-model="mutableData.name" />
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <vs-input
          class="w-full"
          label="Phone"
          v-model="mutableData.phone"
          v-validate="'required|numeric'"
          name="Phone"
        />
        <span class="text-danger text-sm" v-show="errors.has('Phone')">{{ errors.first('Phone') }}</span>
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <vs-input
          class="w-full"
          label="Mobile"
          v-model="mutableData.mobile"
          v-validate="'numeric'"
          name="Mobile"
        />
        <span class="text-danger text-sm" v-show="errors.has('Mobile')">{{ errors.first('Mobile') }}</span>
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <vs-input
          class="w-full"
          label="Email"
          v-model="mutableData.email"
          v-validate="'email'"
          name="Email"
        />
        <span class="text-danger text-sm" v-show="errors.has('Email')">{{ errors.first('Email') }}</span>
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <vs-input
          class="w-full"
          label="Fax"
          v-model="mutableData.fax"
          v-validate="'numeric'"
          name="Fax"
        />
        <span class="text-danger text-sm" v-show="errors.has('Fax')">{{ errors.first('Fax') }}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    data: Object
  },
  data() {
    return {
      mutableData: {
        name: "",
        mobile: "",
        phone: "",
        email: "",
        fax: ""
      }
    };
  },
  mothods: {},
  mounted() {
    this.mutableData.name = this.data.name;
    this.mutableData.mobile = this.data.mobile;
    this.mutableData.phone = this.data.phone;
    this.mutableData.email = this.data.email;
    this.mutableData.fax = this.data.fax;
  },
  watch: {
    mutableData: {
      deep: true,
      handler(val) {
        this.$emit("data", val);
      }
    }
  }
};
</script>